<template>
	<div class="">
		<el-tabs type="border-card" v-model="activeName">
			<el-tab-pane label="Fedex" name="first">
				<LgsFedexTrackQuery></LgsFedexTrackQuery>
			</el-tab-pane>
			<el-tab-pane label="UPS" name="second">
				<LgsUpsTrackQuery></LgsUpsTrackQuery>
			</el-tab-pane>
			<el-tab-pane label="地址/分区查询" name="third">
				<ExpressAddressQueryCheck></ExpressAddressQueryCheck>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
	import LgsFedexTrackQuery from '@/components/StorageCenter/ExpressSheet/LgsFedexTrackQuery.vue';
	import LgsUpsTrackQuery from '@/components/StorageCenter/ExpressSheet/LgsUpsTrackQuery.vue';
	import ExpressAddressQueryCheck from '@/components/StorageCenter/ExpressSheet/ExpressAddressQueryCheck.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		components: {
			LgsFedexTrackQuery,
			LgsUpsTrackQuery,
			ExpressAddressQueryCheck
		},
		watch: {
			// $route: {
			// 	handler(newVal, oldVal) {
			// 		//判断newVal有没有值监听路由变化
			// 		if (newVal != oldVal) {
			// 			location.reload()
			// 		}
			// 	},
			// 	deep: true
			// }
		},
		data() {
			return {
				activeName: 'first',
				detQuery:{}
			};
		},
		activated() {

		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			// initDetQuery(query){
			// 	if(query.cusId && query.whNo && query.daterange){
			// 		this.detQuery = query;
			// 		this.activeName = "second";
			// 	} else {
			// 		this.$message.warning("查询参数缺失，请主动切换至账单明细查询查看");
			// 	}
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
