<template>
	<div class="">
		<div class="tableConTop">
			<el-row>
				<el-col :span="6" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<!-- <el-breadcrumb-item>
							<span class="breadcrumbLink" @click="backPageAction()">
								<i class="el-icon-back iconBack"></i>{{$t('i18nn_ca5a01a5adf20fe7')}}</span>
						</el-breadcrumb-item> -->
						<el-breadcrumb-item>地址/分区查询</el-breadcrumb-item>
					</el-breadcrumb>
					<!-- <span>{{$t('i18nn_2bbc6b12fbdf9019')}}</span> -->
				</el-col>
				<el-col :span="18" class="tableConTopRig">
					<!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
		        <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">undefined</el-button> -->
				</el-col>
			</el-row>
		</div>
		<!-- 修改弹窗 -->
		<!--本页切换，新增、修改、查看等-->
		<!-- <hyPageShow :visible="dialogFormVisible" @updateVisible="updateVisible" title="undefined" :subtitle="dialogFormVisibleMsg"> -->
		<!-- <el-dialog :title="$t('i18nn_24c8f46012a25c89')" :visible.sync="dialogFormVisible" :top="'10px'" custom-class="myFullDialog2"> -->
		<div class="" style="" v-loading="loading">
			<el-card shadow="never">
				<div slot="header">
					<h3>地址校验</h3>
				</div>
				<div>
					<el-form ref="form" inline :size="''" :rules="formRules" :model="form" label-width="100px">
						<div>
							<el-form-item :label="'智能填写'" prop="">
								<!-- <el-input type="textarea" :rows="3" :placeholder="'长地址解析(辅助输入)'" v-model="allAddress" maxlength="500"
									show-word-limit style="width: 800px;" @input="matchAddress()"></el-input> -->
								<longUsAddressParse :openTime="addrParseOpenTime" @parse="matchAddress" :type="1"></longUsAddressParse>
							</el-form-item>
							<!-- <el-button size="small" type="warning" icon="el-icon-attract" @click="matchAddress()">
								智能填充
							</el-button> -->
						</div>

						<el-form-item :label="'地址一'" prop="" required>
							<el-input type="textarea" :rows="2" v-model="form.street1"
								:placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
						</el-form-item>
						<el-form-item :label="'地址二'" prop="">
							<el-input type="textarea" :rows="2" v-model="form.street2"
								:placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
						</el-form-item>
						<el-form-item :label="'城市'" prop="" required>
							<el-input type="textarea" :rows="2" v-model="form.city"
								:placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
						</el-form-item>

						<el-form-item :label="'州'" prop="" required>
							<el-input type="text" v-model="form.stateOrProvinceCode" :placeholder="'二字码'"></el-input>
						</el-form-item>
						<el-form-item :label="'邮编'" prop="" required>
							<el-input type="text" v-model="form.postalCode" :placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
						</el-form-item>
						<el-form-item :label="'国家'" prop="" required>
							<el-input type="text" v-model="form.countryCode" :placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
						</el-form-item>
					</el-form>

					<el-button type="primary" icon="el-icon-search" style="" @click="submitForm('form')">
						校验
					</el-button>

					<div style="margin-top: 10px;">
						<el-descriptions title="校验结果" :column="7" :size="'small'" border>
							<el-descriptions-item label="类型">{{valiData.addTypeName}}</el-descriptions-item>
							<el-descriptions-item label="地址"><span
									v-if="valiData.streetLines">{{valiData.streetLines.join(',')}}</span>,
									<span>{{valiData.city}}</span>,
									<span>{{valiData.stateOrProvinceCode}}</span>,
									<span>{{valiData.postalCode}}</span>,
									<span>{{valiData.countryCode}}</span>
							</el-descriptions-item>
							<!-- 
							<el-descriptions-item label="城市">{{valiData.city}}</el-descriptions-item>
							<el-descriptions-item label="州">{{valiData.stateOrProvinceCode}}</el-descriptions-item>
							<el-descriptions-item label="邮编">{{valiData.postalCode}}</el-descriptions-item>
							<el-descriptions-item label="国家">{{valiData.countryCode}}</el-descriptions-item>
							 -->
							<el-descriptions-item label="residential">{{valiData.residential}}</el-descriptions-item>
						</el-descriptions>
					</div>

				</div>
			</el-card>

			<el-card shadow="never">
				<div slot="header">
					<h3>查询分区</h3>
				</div>
				<div>
					<el-form ref="form2" inline :size="''" :rules="formRules2" :model="form2" label-width="100px"
						v-loading="loading2">

						<el-form-item :label="'发货邮编'" prop="" required>
							<el-input type="text" v-model="form2.fromZip" :placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
						</el-form-item>
						<el-form-item :label="'收货邮编'" prop="" required>
							<el-input type="text" v-model="form2.toZip" :placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
						</el-form-item>

					</el-form>
					<el-button type="primary" icon="el-icon-search" style="" @click="submitForm2('form2')">
						查询
					</el-button>
				</div>
			</el-card>
			<!-- <el-card shadow="never">
					<div slot="header">
						<h3>{{$t('i18nn_ab6ea90b9164b20a')}}</h3>
					</div>
					<div>
						<el-form-item :label="$t('i18nn_466677324278a9a1')" required>
							<el-input type="textarea" :rows="3" :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.qaProgress.qaText"
								maxlength="2000" show-word-limit style="width: 800px;"></el-input>
						</el-form-item>
						
						<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="" v-loading="loadingUploadFile">
							<el-button type="primary" icon="el-icon-paperclip" @click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>
						
							<ul>
								<li v-for="(item,index) in form.qaProgress.attachments" :key="index">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;"
										:z-index="9999" :fit="'contain'" :src="item.url"
										:preview-src-list="form.qaProgress.attachments.map(itemPre=> { return itemPre.url})">
										<div slot="error" class="image-slot">
										  <i class="el-icon-document"></i>
										</div>
									</el-image>
									<a :href="item.url" :title="item.url"
										target="_blank">{{ item.fileName }}</a>
						
									<el-button @click="delFileAction($event, item,index)" type="text" size="mini"
										icon="el-icon-delete" style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
									<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								</li>
							</ul>
						</el-form-item>
						
					</div>
				</el-card> -->


			<!-- <div style="margin: 10px 0;">
				<el-button type="primary" icon="el-icon-tickets" style="width: 220px;" @click="submitForm('form', '1')">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
			</div> -->
		</div>

		<!-- 附件 -->
		<!-- <dialogFileUpload ref="dialogFileUpload" :folder="'storage/QARecProgress/'" :fileKey="'QARecProgressFile'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload> -->

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	import {
		clearObjectVal
	} from '@/utils/common.js';

	// import cusSelFuzzy from '@/components/StorageCenter/components/cusSelFuzzy.vue';
	import longUsAddressParse from '@/components/StorageCenter/components/longUsAddressParse.vue';

	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';

	// import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';

	// import BillingRecords from '@/components/WarehouseCenter2/FinanceMana/BillingRecords.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		components: {
			longUsAddressParse
			// cusSelFuzzy,
			// whNoSelect,
			// HyQuillEditor,

			// dialogFileUpload
			// BillingRecords
		},
		data() {
			return {
				// cusUserId: '',

				// pageTitle: this.$t('i18nn_e8564657bbe9ca53'),

				// FileUploadOpenTime: '',

				// selectOption: {
				// 	wh_no: [],
				// 	wh_qa_type: [],
				// 	wh_qa_biz_type: [],
				// },

				// loading_load: false,
				// leaveMessage:"",
				// loadingUploadFile: false,

				loading: false,
				// allAddress: "",
				addrParseOpenTime:"",
				
				form: {
					"street1": "",
					"street2": "",
					"streetLines": [],
					"city": "",
					"stateOrProvinceCode": "",
					"postalCode": "",
					"countryCode": ""
				},
				formRules: {},

				loading2: false,
				form2: {
					"fromZip": "",
					"toZip": ""
				},
				formRules2: {},

				valiData: {},
				// form: {
				// 	"title": null, //this.$t('i18nn_7431e76029678ec7'),
				// 	"whNo": null, //this.$t('i18nn_c944a6686d996ab3'),
				// 	// "cusId": null,
				// 	"qaType": null, //问题类型
				// 	"remark": null, //this.$t('i18nn_15b3627faddccb1d'),
				// 	qaBizType: null,
				// 	relationId: null,
				// 	relationNo: null,
				// 	applyName: null,
				// 	// "qaProgress": {
				// 	// 	"qaMoreText": null, //this.$t('i18nn_74f0f436fa58dfe4'),
				// 	// 	"qaText": null, //this.$t('i18nn_3d23fd1011cdc075')
				// 	// 	"attachments":[]
				// 	// }
				// },


				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc', //desc降序，asc升序

				// 	whNo: '',
				// }
			};
		},
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {
			//数据字典
			// getDicData(['wh_qa_type', 'wh_qa_biz_type'],
			// 	(data) => {
			// 		this.selectOption.wh_qa_type = data['wh_qa_type'];
			// 		this.selectOption.wh_qa_biz_type = data['wh_qa_biz_type'];
			// 	});
		},
		methods: {
			initData() {
				this.resetForm();
			},
			// backPageAction() {
			// 	if(window.history.length>2){
			// 		this.$router.go(-1);
			// 	} else {
			// 		this.$router.push({
			// 			name: 'QARecList',
			// 			query: {}
			// 		});
			// 	}
			// },

			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// },

			// 富文本
			// onEditorChange(html) {
			// 	// this.form.contentStr = html;
			// 	this.form.qaProgress.qaMoreText = html;
			// },

			//添加附件
			// openAddFile() {
			// 	// this.FileUploadOpenIndex = index;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },

			//附件上传成功回调
			// FileUploadSuccess(data) {
			// 	console.log('FileUploadSuccess', data);
			// 	let fileList = data.map(item => {
			// 		return {
			// 			fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 			url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
			// 			type: "90"
			// 		}
			// 	});
			// 	if(!this.form.qaProgress.attachments){
			// 		this.form.qaProgress.attachments = [];
			// 	}
			// 	// this.form.qaProgress.attachments = fileList;
			// 	this.form.qaProgress.attachments = this.form.qaProgress.attachments.concat(fileList);
			// 	this.$forceUpdate();
			// },
			//删除附件
			// delFile(event, index) {
			// 	event.stopPropagation();
			// 	this.form.qaProgress.attachments.splice(index, 1);
			// 	this.$forceUpdate();
			// },
			// //后端删除
			// delFileAction(event,row,index) {
			// 	event.stopPropagation();
			//   this.loadingUploadFile = true;
			//   this.$http
			//     .delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
			//     .then(({ data }) => {
			//       this.loadingUploadFile = false;
			//       if (200 == data.code) {
			// 				this.form.qaProgress.attachments.splice(index, 1);
			// 				this.$forceUpdate();
			// 				this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			//       } else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			// 			this.loadingUploadFile = false;
			// 			this.$message.warning(data.msg ? data.msg : '删除失败!');
			//     });
			// },

			resetForm(formName) {
				console.log(formName);

				// if (this.$refs[formName]) {
				// 	this.$refs[formName].resetFields();
				// } else {
				// 	console.log('this.$refs[formName]', this.$refs[formName]);
				// }

				this.form = clearObjectVal(this.form);
				this.form.countryCode = "US";

				this.form2 = clearObjectVal(this.form2);
				
				//智能解析地址
				this.addrParseOpenTime  = new Date().getTime();
				// this.form = {
				// 	"title": null, //this.$t('i18nn_7431e76029678ec7'),
				// 	"whNo": null, //this.$t('i18nn_c944a6686d996ab3'),
				// 	"qaType": null, //问题类型
				// 	// "cusId": null,
				// 	"remark": null, //this.$t('i18nn_15b3627faddccb1d'),
				// 	qaBizType: null,
				// 	relationId: null,
				// 	relationNo: null,
				// 	applyName:null,
				// 	"qaProgress": {
				// 		"qaMoreText": null, //this.$t('i18nn_74f0f436fa58dfe4'),
				// 		"qaText": null, //this.$t('i18nn_3d23fd1011cdc075')
				// 		"attachments":[]
				// 	}
				// }

				// this.$nextTick(() => {
				// 	this.$refs.whNoSelect.init();
				// });
			},

			//尝试匹配
			// tryAddrCode(text) {
			// 	// 二字码尝试匹配
			// 	let adr_prov = text.match(/[A-Z]{2}/g);
			// 	if (adr_prov && adr_prov[0]) {
			// 		this.form.stateOrProvinceCode = adr_prov[0].trim();
			// 	}
			// 	//邮编尝试匹配
			// 	let adr_postalCode = text.match(/[0-9]{5}/g);
			// 	if (adr_postalCode && adr_postalCode[0]) {
			// 		this.form.postalCode = adr_postalCode[0].trim();
			// 	}
			// },

			//智能匹配
			matchAddress(addrAll) {
				
				this.form = {
					"street1": addrAll.street1,
					"street2": "",
					"streetLines": [],
					"city": addrAll.city,
					"stateOrProvinceCode": addrAll.stateOrProvinceCode,
					"postalCode": addrAll.postalCode,
					"countryCode": addrAll.countryCode,
				}
				
				// let allAddress = this.allAddress;
				// // 1033 N Todd Ave, Azusa, CA 91702美国
				// // 1033 N Todd Ave,Azusa,CA,91702,US
				// //分割
				// let addressList = allAddress.split(',')

				// // this.form = {
				// // 	"street1": "",
				// // 	"street2": "",
				// // 	"streetLines": [],
				// // 	"city": "",
				// // 	"stateOrProvinceCode": "",
				// // 	"postalCode": "",
				// // 	"countryCode": "US"
				// // }
				// if (addressList[0]) {
				// 	this.form.street1 = addressList[0].trim();
				// }
				// if (addressList[1]) {
				// 	this.form.city = addressList[1].trim();
				// }
				// if (addressList[2]) {
				// 	this.form.stateOrProvinceCode = addressList[2].trim();
				// 	this.tryAddrCode(addressList[2]);
				// }
				// if (addressList[3]) {
				// 	this.form.postalCode = addressList[3].trim();
				// 	this.tryAddrCode(addressList[3]);
				// }
				// if(addressList[4]){
				// 	// this.form.postalCode = addressList[4];
				// 	this.tryAddrCode(addressList[4]);
				// }
			},

			//提交信息
			submitForm(formName, type) {
				let formData = Object.assign({}, this.form);
				formData.streetLines = [];
				if (formData.street1) {
					formData.streetLines.push(formData.street1);
				}
				if (formData.street2) {
					formData.streetLines.push(formData.street2);
				}
				this.postData(this.$urlConfig.WhExpressAddressValidata, [formData], data => {
					// console.log('postData', data);
					this.$message.success('查询成功');
					if (data.addressList && data.addressList.length > 0) {
						this.valiData = data.addressList[0];
					} else {
						this.$message.warning('校验无数据');
					}

					// this.backPageAction();
				});
			},

			submitForm2(formName, type) {
				let formData = Object.assign({}, this.form2);

				this.postData(this.$urlConfig.WhExpressAddressZone, formData, data => {
					// console.log('postData', data);
					this.$message.success('查询成功');
					// this.backPageAction();
				});
			},

			// //选择行
			// handleCurrentChange(row, event, column) {
			// 	// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			// },
			// //多选
			// handleSelectionChange(val) {
			// 	console.log(val);
			// 	this.multipleSelection = val;
			// },

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback(data.data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//提交数据
			// delData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.delete(url, formData)
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback(data.data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
			//编辑详情的数据
			// getDetData(id) {
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhQARecProgressGet + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			
			// 			console.log('data',data);
			// 			this.loading = false;
			// 			if (200 == data.code && data.data) {
			// 				//表格显示数据
			// 				// this.form = data.data;


			// 				// this.$nextTick(() => {
			// 				// 	this.$refs.whNoSelect.init(data.data.whNo);
			// 				// 	this.$refs.cusSelFuzzy.init(data.data.userId);
			// 				// });

			// 				// this.tableData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('列表数据，请求失败！');
			// 			this.loading = false;
			// 		});
			// },
			//查询仓库
			// getWhNoData() {
			// 	this.$http
			// 		.get(this.$urlConfig.whNoPageList)
			// 		.then(({ data }) => {
			// 			console.log('查询，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.rows) {
			// 				this.selectOption.wh_no = data.rows;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询仓库失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.errorData'));
			// 			this.$message.error('查询仓库失败,请重试！');
			// 		});
			// },
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>