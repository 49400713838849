<template>
	<div class="mainTem">
		<div class="tableConTable">
			<!-- <div class="buyerHeader"><h3 class="buyerHeaderTit">{{$t('i18nn_eb763bfab5d5a930')}}</h3></div> -->
			<div class="tableConTop">
				<el-row>
					<el-col :span="8" class="tableConTopLeft">
						<h3>Fedex<span>{{$t('i18nn_7c3fc1953013745c')}}</span>
						</h3>

					</el-col>
					<el-col :span="16" class="tableConTopRig">

						<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
						</el-button>
			  </el-col>
				</el-row>
			</div>
			<div class="filterCon" style="">

				<ul class="filterConList">
					
					<li>
						<span>{{$t('i18nn_8b9618de2e85645e')}}</span>
						<!-- <el-input
									type="text"
									v-model="filterData.code"
									size="small"
									clearable
									@keyup.enter.native="initData()"
									maxlength="50"
									placeholder="undefined"
									style="width: 180px;"
								/> -->
						<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 8 }" v-model="filterData.code"
							size="small" clearable show-word-limit maxlength="1000" :placeholder="$t('FormMsg.Select_long_LgsFedexTrackQuery_1')"
							style="width: 220px;vertical-align: middle; " />
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
							{{ $t('i18nn_1e7246dd6ccc5539') }}</el-button>
					</li>
				</ul>
				<!-- </el-col> -->
				<!-- </el-row> -->
			</div>



			<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
				
				
				<el-collapse accordion>
				  <el-collapse-item v-for="(item,index) in tableData" :key="index">
				    <template slot="title">
							<h2 style="color:#409EFF;">
								<i class="el-icon-takeaway-box"></i>
								<span style="padding: 0 10px;">{{item.trackingNumber}}</span>
								<i class="el-icon-arrow-down"></i>
							</h2>
				      
				    </template>
				    <div>
							<LgsFedexTrackSimTem :openTime="index" :itemData="item"></LgsFedexTrackSimTem>
						</div>
				  </el-collapse-item>
				</el-collapse>
				
			</div>
			<!-- <div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div> -->
		</div>

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
		<!-- excel 导出排序 -->
		<!-- <whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhFuerthertryDetPageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom> -->
	</div>
</template>
<script>
	import LgsFedexTrackSimTem from '@/components/StorageCenter/ExpressSheet/LgsFedexTrackSimTem.vue';
	export default {
		components: {
			LgsFedexTrackSimTem
		},
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		// props: {
		// 	// mobile:"",
		// 	// isSel:{
		// 	//   default: function() {
		// 	//     return false
		// 	//   },
		// 	//   type: Boolean
		// 	// },
		// 	exprId: {
		// 		default: function() {
		// 			return '';
		// 		},
		// 		type: String
		// 	}
		// },
		data() {
			return {
				//excel导出
				// excelOpenTime: '',
				// excelHead: [],
				// expExcelData: [],
				// excelName: '',
				// excelOption: {},
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				// pickerOptions: {
				// 	// disabledDate(time) {
				// 	//   return time.getTime() >= Date.now();
				// 	// },
				// 	shortcuts: [
				// 		{
				// 			text: 'Latest Week',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Month',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		}
				// 		// {
				// 		//   text: 'Last Three Months',
				// 		//   onClick(picker) {
				// 		//     const end = new Date();
				// 		//     const start = new Date();
				// 		//     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				// 		//     picker.$emit('pick', [start, end]);
				// 		//   }
				// 		// }
				// 	]
				// },

				loading: false,

				loading_load: false,
				tableData: [],

				multipleSelection: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type: [],
					// wh_size_unit: [],
					// // wh_packing_type:[],
					// wh_car_tms_type: [],
					wh_no: [],
					// labelReadyList: [
					// 	{
					// 		value: '',
					// 		label: this.$t('i18nn_16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '0',
					// 		label: this.$t('i18nn_b44a4a27cac7433e')
					// 	},
					// 	{
					// 		value: '1',
					// 		label: this.$t('i18nn_27372599992ff988')
					// 	}
					// ],
					// labelStatusList: [
					// 	{
					// 		value: '',
					// 		label: this.$t('i18nn_16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '10',
					// 		label: this.$t('i18nn_cce168301447d1ba')
					// 	},
					// 	{
					// 		value: '20',
					// 		label: this.$t('i18nn_a0acfaee54f06458')
					// 	},
					// 	{
					// 		value: '60',
					// 		label: this.$t('i18nn_7fa1fea309b0fd8d')
					// 	},
					// 	{
					// 		value: '70',
					// 		label: this.$t('i18nn_bcef26d98d690aba')
					// 	},
					// 	{
					// 		value: '90',
					// 		label: this.$t('i18nn_4ca88dd2e7071844')
					// 	}
					// ]
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// agentUser:'',
					// whNo:'',

					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
					// exprStatus: '',
					code: "",
					// whNo: '',
					// orderNumber: '',
					// // cusNo: '',
					// userId:'',
					// commitDate: '',
					// keyword: '',
					// labelReady: '',
					// labelStatus: '',
					// agentUser: ''
					// "accountPeriod":"",
					// packId: "",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: '',
				}
			};
		},
		// watch: {
		// 	exprId: function(newVal, oldVal) {
		// 		console.log('watchKey');
		// 		// if (newVal) {
		// 		// console.log('watch openDateTime HyUpLoadImg.vue');
		// 		this.initData();
		// 		// }
		// 	}
		// },
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				// if (this.exprId) {
				this.tableData = [];
				this.getPageData();
				// }
				// this.getDicData();
			},
			// handleSelectStatus(key, keyPath) {
			//   // console.log(key, keyPath);
			// 	this.filterData.labelStatus = key;
			// 	this.initData();
			// },
			// changeAgentUser(val) {
			// 	this.filterData.agentUser = val;
			// 	this.initData();
			// },
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.filterData.userId = data.userId;
			// 	// this.filterData.cusNo = data.cusNo;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			//导出 excel
			// exportExcel() {
			// 	let columns = [
			// 		{
			// 			title: this.$t('i18nn_c944a6686d996ab3'),
			// 			key: 'whNo'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_29991afa9781d554'),
			// 			key: 'cusName'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_e716ca411b2142e3'),
			// 			key: 'carrier'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_3ad90a648c6bfa99'),
			// 			key: 'carrierServiceLevel'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_abf9f4b8c93d641e'),
			// 			key: 'orderNumber'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_310c8b0f4df16e08'),
			// 			key: 'isBuyName'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_82b1c8e36ef45bb8'),
			// 			key: 'isRecordName'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_06dd7cb65641390b'),
			// 			key: 'labelReadyName'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_90429ac39f4f4634'),
			// 			key: 'labelStatusName'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_1324a1d91ae3de53'),
			// 			key: 'labelUrl'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_c6b76f2746d7dc6f'),
			// 			key: 'labelSeq'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_e5c3fd867ea4c8e6'),
			// 			key: 'lgsKeyCode'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_48ebfc4319cbac59'),
			// 			key: 'labelOrderNum'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_4531476fa35570f0'),
			// 			key: 'tranckingNo'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_ab6994d16b9b4366'),
			// 			key: 'shippingPrice'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_d353bf2887ca0603'),
			// 			key: 'serviceFee'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_0deaa0a62ac136b6'),
			// 			key: 'sellerFee'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_3c4a40675f59cd1c'),
			// 			key: 'titalServiceFee',
			// 			types: 'custom',
			// 			formatter:function(row){
			// 				if(row){
			// 					// return parseFloat(row.shippingPrice)+parseFloat(row.serviceFee)
			// 					return (parseFloat(row.shippingPrice?row.shippingPrice:0) + parseFloat(row.serviceFee?row.serviceFee:0)+ parseFloat(row.sellerFee?row.sellerFee:0)).toFixed(2);
			// 				} else {
			// 					return '';
			// 				}
			// 			}
			// 		},
			// 		{
			// 			title: this.$t('i18nn_15b3627faddccb1d'),
			// 			key: 'remark'
			// 		},
			// 		// {
			// 		// 	title: this.$t('f5d43732e3f6cf4d'),
			// 		// 	key: 'attachments',
			// 		// 	types: 'custom',
			// 		// 	formatter: function(row) {
			// 		// 		return row.attachments
			// 		// 			.map(item => {
			// 		// 				return item.url;
			// 		// 			})
			// 		// 			.join(',');
			// 		// 	}
			// 		// },
			// 		{
			// 			title: this.$t('i18nn_5f6b842a6ff748ab'),
			// 			key: 'labelTime'
			// 		},
			// 	];
			// 	let Data = this.tableData;

			// 	// let data_dom = JQ('#ex_table').find(".el-table__body");
			// 	// let obj_key_img = {};
			// 	// // console.log(data_dom.find("tr"));
			// 	// data_dom.find("tr").each((index, domEle)=>{
			// 	// 	// console.log("index",index);
			// 	// 	let imgObj = JQ(domEle).find("td .cell img");
			// 	// 	// console.log("imgObj",imgObj);
			// 	// 	if(imgObj){
			// 	// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
			// 	// 	}
			// 	// })
			// 	// // console.log("obj_key_img",obj_key_img);
			// 	// Data.forEach(item=>{
			// 	// 	item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
			// 	// })
			// 	this.expExcelData = Data;
			// 	this.excelHead = columns;
			// 	this.excelName = 'ExpressSheetDetList';
			// 	this.excelOption = { height: 20 };
			// 	this.excelOpenTime = new Date().getTime();

			// 	// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm) {
			//   // console.log(formParm);
			//   this.dialogFormVisible = true;
			//   let form = Object.assign({}, formParm);
			//   console.log('form',form);
			//   // // 重置
			//   this.resetForm('form');
			//   if (null === formParm) { //新增

			//     this.form.weightUnit = '1';
			//     this.form.volumeUnit = '1';
			//     this.form.whFeeType = '1';

			//     this.dialogFormStatus = 0;
			//     // form.subUserId = null;
			//     // form.userSubUserId = null;
			//     // form.state = true;
			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   } else { //修改
			//     this.dialogFormStatus = 1;
			//     // form.state = form.state === '0' ? true : false;

			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   }
			//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//   //       type: 'warning',
			//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//   //     });
			// },
			//打开编辑
			// openEdit(event, row, index) {
			//   event.stopPropagation();
			//   this.openDioalog(row, this.$t('FormMsg.Edit'));
			// },

			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 		// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			// this.$message({
			// 			//   type: 'success',
			// 			//   message: '删除成功!'
			// 			// });
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {
			// 			// this.$message({
			// 			//   type: 'info',
			// 			//   message: this.$t('i18nn_2e58cb9b52e2a214')
			// 			// });
			// 		});
			// },
			// // //删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);

			// 	this.postData(
			// 		this.$urlConfig.WhThridLabelInfoDetDel + '/' + row.id,
			// 		{},
			// 		() => {
			// 			this.initData();
			// 			this.$message.success('删除成功！');
			// 		},
			// 		'delete'
			// 	);
			// },

			// hyUpLoadImg1: function(childValue) {
			//   // childValue就是子组件传过来的值
			//   this.form.goodsImg = childValue;
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {

			//   this.$refs[formName].validate((valid) => {

			//     if (valid) {
			//       let formData = Object.assign({}, this.form);
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {

			//         formData.id = null;
			//         formData.userId = this.UserInfo.id;

			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);

			//       } else {

			//         formData.userId = this.UserInfo.id;
			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);
			//       }

			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   if (this.$refs[formName]) {
			//     this.$refs[formName].resetFields();

			//   } else {
			//     console.log('this.$refs[formName]',this.$refs[formName]);
			//   }
			// },

			//提交信息
			// postData(url, formData) {
			//   // let _this = this;
			//   this.loading = true;

			//   // formData.state = formData.state ? '0' : '1';
			//   this.$http.delete(url, formData)
			//     .then(({ data }) => {
			//       console.log(this.$t('tips.submitSuccess'));
			//       console.log(data);
			//       
			//       this.loading = false;
			//       if (200 == data.code) {
			//         this.dialogFormVisible = false;
			//         this.getPageData();
			//         this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			//           type: 'success',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       } else {
			//         // if (!data.msg) {
			//         //   data.msg = this.$t('tips.submitError');
			//         // }
			//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t("tips.submitError"));
			//       this.loading = false;
			//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//查询数据
			// serPageData() {
			// 	// this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			//分页的筛选项数据
			pageFilterData() {
				// let startTime = '';
				// let endTime = '';
				// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				// 	startTime = this.filterData.daterange[0];
				// 	endTime = this.filterData.daterange[1];
				// } else {
				// 	this.$message.warning(this.$t('tips.Please_Sel_date'));
				// }
				// let filterData = code.split("\n");
				return {
					// exprId: this.exprId, //"orde的ID",
					// userId: this.filterData.userId ? this.filterData.userId : null,
					// // proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// // exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,
					// orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
					// // userId: this.filterData.cusNo ? this.filterData.cusNo : null,
					// keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// labelReady: this.filterData.labelReady ? this.filterData.labelReady : null,
					// labelStatus: this.filterData.labelStatus ? this.filterData.labelStatus : null,
					// whNo: this.filterData.whNo ? this.filterData.whNo : null,

					// createTimeStart: startTime ? startTime : null,
					// createTimeEnd: endTime ? endTime : null
				};
			},
			//请求分页数据
			getPageData() {
				// let filterData = Object.assign(
				// 	{
				// 		offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
				// 		limit: this.pagination.page_size //当前页显示数目
				// 	},
				// 	this.pageFilterData()
				// );

				// let filterData = code.split("\n");

				let codeList = [];
				if (this.filterData.code) {
					codeList = this.filterData.code.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhFedexLgsTrackQuery, codeList)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//撤销打单
			// CancelPrintingAction(event) {
			// 	event.stopPropagation();
			// 	// this.getPriterOrderRate(row,index,row.id);
			// 	let parm = [];
			// 	// 	if (row) {
			// 	// 		parm = [row.id];
			// 	// 	} else {
			// 	// 		//多条
			// 	let dataList = this.multipleSelection;
			// 	// let dataList = this.tableData;
			// 	if (dataList.length < 1) {
			// 		this.$message.warning(this.$t('tips.Please_Sel'));
			// 		return;
			// 	}
			// 	// 		// if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
			// 	// 		//   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
			// 	// 		//   return;
			// 	// 		// }

			// 	let dataListParm = dataList.map(v => v.id);
			// 	parm = dataListParm;
			// 	// 	}
			// 	this.$confirm('确定 撤销打单 吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhThridLabelCancelPrinting, parm, () => {
			// 				this.initData();
			// 				this.$message.success(this.$t('i18nn_778d871227234f32'));
			// 				// this.$router.push({name:'WhPackage',query:{open:'add'}});
			// 			});
			// 		})
			// 		.catch(() => {
			// 			// this.$message({
			// 			//   type: 'info',
			// 			//   message: this.$t('i18nn_2e58cb9b52e2a214')
			// 			// });
			// 		});
			// },
			//取消面单
			// CancelOrderAction(event) {
			// 	event.stopPropagation();
			// 	// this.getPriterOrderRate(row,index,row.id);
			// 	let parm = [];
			// 	// 	if (row) {
			// 	// 		parm = [row.id];
			// 	// 	} else {
			// 	// 		//多条
			// 	let dataList = this.multipleSelection;
			// 	// let dataList = this.tableData;
			// 	if (dataList.length < 1) {
			// 		this.$message.warning(this.$t('tips.Please_Sel'));
			// 		return;
			// 	}
			// 	// 		// if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
			// 	// 		//   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
			// 	// 		//   return;
			// 	// 		// }

			// 	let dataListParm = dataList.map(v => v.id);
			// 	parm = dataListParm;
			// 	// 	}
			// 	this.$confirm('确定 取消面单 吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhThridLabelCancelOrder, parm, () => {
			// 				this.initData();
			// 				this.$message.success(this.$t('i18nn_854a071f3cbf2761'));
			// 				// this.$router.push({name:'WhPackage',query:{open:'add'}});
			// 			});
			// 		})
			// 		.catch(() => {
			// 			// this.$message({
			// 			//   type: 'info',
			// 			//   message: this.$t('i18nn_2e58cb9b52e2a214')
			// 			// });
			// 		});
			// },

			//批量下载附件
			// batchDownloadAction(event, row) {
			// 	event.stopPropagation();

			// 	let dataList = this.multipleSelection;
			// 	// let dataList = this.tableData;
			// 	if (dataList.length < 1) {
			// 		this.$message.warning(this.$t('tips.Please_Sel'));
			// 		return;
			// 	}

			// 	if (!!window.ActiveXObject || 'ActiveXObject' in window) {
			// 		this.$message.warning(this.$t('i18nn_2de6c4eb2e40cf1f'));
			// 	}
			// 	// let dataList = this.tableData;
			// 	// if (dataList.length < 1) {
			// 	// 	this.$message.warning(this.$t('7b80e66b535a3732'));
			// 	// 	return;
			// 	// }
			// 	let files = [];
			// 	// console.log('dataList', dataList);
			// 	dataList.forEach(item => {
			// 		if(item.labelUrl){
			// 			files.push(item.labelUrl);
			// 		}
			// 	});
			// 	// files.push('https://storage-prod.vitedirect.com/labels/2022/4/272752978128.pdf');
			// 	// console.log('files', files);
			// 	if (files.length < 1) {
			// 		this.$message.warning(this.$t('i18nn_c26d5c523b0b69d3'));
			// 		return;
			// 	}
			// 	this.$message.success('当前页，共 ' + files.length + ' 个面单，正在压缩');

			// 	fileZipAndDownload(files, 'ExpressSheetDetListLabel');
			// },

			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event,row) {
			//   event.stopPropagation();
			//   this.$emit('selectRow',row)
			// },
			//提交数据
			postData(url, formData, callback, type) {
				// let _this = this;
				this.loading = true;
				let http = {};
				if ('delete' == type) {
					http = this.$http.delete(url, formData);
				} else {
					http = this.$http.put(url, formData);
				}
				http
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.submitError');
							}
							this.$alert(data.msg, this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			}
			//查询数据字典
			// getDicData() {
			//   // let _this = this;
			//   // console.log(keyword);

			//   // this.loading_load = true;
			//   this.$http
			//     .put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			//     .then(({ data }) => {
			//       console.log('查询数据字典，请求成功');
			//       console.log(data);
			//       if (200 == data.code && data.data) {
			//         // this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			//         // this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			//         // this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			//         // this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			//         // this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			//         this.selectOption.wh_no = data.data['wh_no'];
			//       } else {
			//         if (!data.msg) {
			//           data.msg = this.$t("tips.submitError");
			//         }
			//         this.$message.warning(data.msg);
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('查询数据字典接口，请求失败');
			//       this.$message.error(this.$t("tips.submitRequestError"));
			//     });
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>
